<template>
  <div class="search-bar">
    Search:
    <input type="search" v-model="searchText">&#x200b;
    <select v-model="searchField">
      <option value="">-- All fields --</option>
      <option v-for="([fieldName, label]) in availableSearchFields" :value="fieldName"
              :key="fieldName">
        {{ label }}
      </option>
    </select>
  </div>
  <table class="list">
    <thead>
      <tr>
        <template v-for="([fieldName, label]) in fields" :key="label">
          <th @click="sortBy(fieldName, true)" class="clickable"
              v-if="rows[0] && fieldName in rows[0]">
            {{ label }}
            <template v-if="sortByField === fieldName">
              <span v-if="sortOrder"> &#x1F781;</span>
              <span v-else> &#x1F783;</span>
            </template>
            <span v-else style="visibility: hidden;"> &#x1F783;</span>
          </th>
          <th v-else>{{ label }}</th>
        </template>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(row, idx1) in sortedRows" :key="idx1">
        <td v-for="([fieldName]) in fields" :key="fieldName"
            :class="{actions: !(fieldName in row)}">
          <slot :name="`field-${fieldName}`" v-if="$slots[`field-${fieldName}`]"
                :value="row[fieldName]" :row="row" />
          <template v-else>{{ row[fieldName] }}</template>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script setup>
import { ref, computed } from 'vue';

// eslint-disable-next-line no-undef
const props = defineProps(['fields', 'rows', 'defaultSort']);

const availableSearchFields = computed(() => {
  if (!props.rows.length) return [];
  return props.fields.filter(([fieldName]) => {
    const value = props.rows[0][fieldName];
    return typeof value === 'string' || value instanceof String;
  });
});

let defaultSortField;
let defaultSortOrder;
if (props.defaultSort) {
  // eslint-disable-next-line vue/no-setup-props-destructure
  [defaultSortField, defaultSortOrder] = props.defaultSort;
} else if (props.rows.length) {
  defaultSortField = props.fields.find(([fieldName]) => fieldName in props.rows[0]);
  defaultSortOrder = true;
} else {
  // eslint-disable-next-line vue/no-setup-props-destructure
  [[defaultSortField]] = props.fields;
  defaultSortOrder = true;
}

const sortByField = ref(defaultSortField);
const sortOrder = ref(defaultSortOrder);
const searchText = ref('');
const searchField = ref('');

function sortBy(fieldName, defaultOrder) {
  if (!props.rows.length || !(fieldName in props.rows[0])) return;
  if (fieldName === sortByField.value) {
    sortOrder.value = !sortOrder.value;
  } else {
    sortByField.value = fieldName;
    sortOrder.value = defaultOrder;
  }
}

function isValidIPv4(ip) {
  const ipv4Regex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
  return ipv4Regex.test(ip);
}
const sortedRows = computed(() => {
  let sr = props.rows.toSorted((a, b) => {
    const aF = a[sortByField.value];
    const bF = b[sortByField.value];
    const oV = sortOrder.value ? 1 : -1;

    function calcD(x, y) {
      let d;
      if (x == null && y == null) {
        // Null sort #1
        d = 0;
      } else if (x == null || y == null) {
        // Null sort #2
        d = x == null ? -1 : 1;
      } else if (Number.isFinite(x)) {
        // Number sort
        d = x - y;
      } else if (typeof x === 'boolean') {
        // Boolean sort
        d = (x ? 1 : 0) - (y ? 1 : 0);
      } else if (isValidIPv4(x) && isValidIPv4(y)) {
        // IPv4 sort
        const oA = x.split('.').map((octet) => parseInt(octet, 10));
        const oB = y.split('.').map((octet) => parseInt(octet, 10));
        d = [0, 1, 2, 3].map((i) => oA[i] - oB[i]).find((f) => f !== 0) ?? 0;
      } else {
        // string sort
        d = x.localeCompare(y);
      }
      return d;
    }

    return (calcD(aF, bF) || calcD(a.id, b.id)) * oV;
  });
  if (searchText.value) {
    const sf = searchField.value === '' ? props.fields.map((f) => f[0]) : [searchField.value];
    const re = new RegExp(searchText.value.replace(/(\W)/g, '\\$1'), 'i');
    console.log('sf', sf);
    console.log('re', re);
    const cb = (row) => sf.some((f) => re.test(row[f]));
    sr = sr.filter(cb);
  }
  return sr;
});
</script>

<style lang="scss" scoped>
td.actions {
  white-space: nowrap;
  width: 0;
}

.search-bar {
  background-color: #ccc;
  padding: 5px 8px 5px 20%;
  margin-bottom: 5px;
  border: 1px solid black;
  border-radius: 5px;
}
</style>
