<template>
  <div>
    <h3>Site-wide settings</h3>

    <table class="list">
      <tr>
        <th>Code</th>
        <th>Value</th>
        <th>Actions</th>
      </tr>
      <tr v-for="setting in allSettings" :key="setting.code">
        <td>{{ setting.code }}</td>
        <td>{{ setting.value }}</td>
        <td>
          <box-icon name="edit" color="blue" @click="editSetting(setting)"
                    class="clickable" title="edit" />
        </td>
      </tr>
    </table>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import myaxios from '../libs/myaxios';

// eslint-disable-next-line no-undef
const emit = defineEmits(['update:layout']);
emit('update:layout', 'default');

const allSettings = ref([]);

async function getAllSettings() {
  ({ data: allSettings.value } = await myaxios.get('/settings/all'));
}

async function editSetting(setting) {
  // eslint-disable-next-line no-alert
  const newVal = prompt(`Enter the new value for setting "${setting.code}"`, setting.value);
  if (!newVal) return;
  await myaxios.put(`/settings/${setting.code}`, {
    value: newVal,
  });
  await getAllSettings();
}
getAllSettings();
</script>
