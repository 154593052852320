<template>
  <div>
    <h3>MinIOs</h3>

    <p>
      Refresh interval:
      <input type="range" min="2" max="10" v-model="refreshInterval">
      {{ refreshInterval }} secs
    </p>

    <SortableTable :rows="fullServers" :fields="[
      ['id', 'ID'],
      ['mnemonic_name', 'Mnemonic name'],
      ['diskFull', 'Disk % full'],
    ]">
      <template #field-diskFull="{value}">
        {{ value }} %
      </template>
    </SortableTable>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useIntervalFn } from '@vueuse/core';
import myaxios from '../libs/myaxios';
import SortableTable from '@/components/SortableTable.vue';

// eslint-disable-next-line no-undef
const emit = defineEmits(['update:layout']);
emit('update:layout', 'default');

const servers = ref([]);
const minioData = ref({});
const refreshInterval = ref(5);

const fullServers = computed(() => servers.value.map((server) => {
  const ret = { ...server };
  ret.diskFull = minioData.value[server.ip_int]?.disk.space;
  return ret;
}));

async function getServers() {
  try {
    ({ data: servers.value } = await myaxios.get('/servers', { params: { type: 'MINIO' } }));
  } catch (err) {
    // eslint-disable-next-line no-alert
    alert("Couldn't fetch servers list");
  }
}

async function getMinioData() {
  try {
    ({ data: minioData.value } = await myaxios.get('/minios'));
  } catch (err) {
    // eslint-disable-next-line no-alert
    alert("Couldn't fetch encoder data");
  }
}

useIntervalFn(getMinioData, computed(() => refreshInterval.value * 1_000), {
  immediateCallback: true,
});
getServers();
</script>
