<template>
  <div>
    <h3>Overview</h3>
    2+2={{2+2}}
  </div>
</template>

<script setup>
// eslint-disable-next-line no-undef
const emit = defineEmits(['update:layout']);
emit('update:layout', 'default');
</script>
