<template>
  <div>
    <h3>Proxies</h3>

    <MyModal v-if="modalVisible">
      <template #header>Add proxy</template>

      <global-events @keydown.esc="modalVisible = false" />

      <form @submit.prevent="submitModal" id="form">
        <table class="form">
          <tr v-if="modalForm.id">
            <th>ID:</th>
            <td>
              <input type="text" v-model="modalForm.id" disabled />
            </td>
          </tr>
          <tr>
            <th>Name:</th>
            <td>
              <input type="text" v-model.trim="modalForm.name" v-focus />
            </td>
          </tr>
          <tr>
            <th>IP:</th>
            <td>
              <input type="text" v-model.trim="modalForm.ip" />
            </td>
          </tr>
          <tr>
            <th>Network receive bandwidth (bytes per second):</th>
            <td>
              <input type="number" v-model.number="modalForm.netRxBw" />
            </td>
          </tr>
          <tr>
            <th>Network transmit bandwidth (bytes per second):</th>
            <td>
              <input type="number" v-model.number="modalForm.netTxBw" />
            </td>
          </tr>
          <tr>
            <th>Disk receive bandwidth (bytes per second):</th>
            <td>
              <input type="number" v-model.number="modalForm.diskRxBw" />
            </td>
          </tr>
          <tr>
            <th>Disk transmit bandwidth (bytes per second):</th>
            <td>
              <input type="number" v-model.number="modalForm.diskTxBw" />
            </td>
          </tr>
        </table>
      </form>

      <template #footer>
        <button @click="modalVisible = false">Cancel</button>&#x200b;
        <button type="submit" form="form">{{ modalForm.id ? 'Update' : 'Add' }}</button>
      </template>
    </MyModal>

    <div class="list-bar">
      <div>
        <button @click="openModal(null)">+ Add encoder</button>
      </div>
      <div style="text-align: center;">
        <input type="search" v-model="search" placeholder="Search..." />
      </div>
      <div />
    </div>

    <table class="list">
      <tr>
        <th>ID</th>
        <th>Name</th>
        <th>IP</th>
        <th>Net Rx</th>
        <th>Net Tx</th>
        <th>Disk Rx</th>
        <th>Disk Tx</th>
        <th></th>
      </tr>
      <tr v-for="proxy in displayedProxies" :key="proxy.id">
        <td style="text-align: center;">{{ proxy.id }}</td>
        <td>{{ proxy.name }}</td>
        <td>{{ proxy.ip }}</td>
        <td>{{ proxy.netRxBw }}</td>
        <td>{{ proxy.netTxBw }}</td>
        <td>{{ proxy.diskRxBw }}</td>
        <td>{{ proxy.diskTxBw }}</td>
        <td class="actions">
          <box-icon name="edit" color="blue" @click="openModal(proxy)"
                    class="clickable" title="edit" />
          <box-icon name="x-circle" color="red" @click="del(proxy)"
                    class="clickable" title="delete" />
        </td>
      </tr>
    </table>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import myaxios from '../libs/myaxios';
// import myaxios from '../libs/myaxios';

// eslint-disable-next-line no-undef
const emit = defineEmits(['update:layout']);
emit('update:layout', 'default');

const see = '- visit network inspector in browser devtools for more';

const proxies = ref([]);
const modalVisible = ref(false);
const modalForm = ref({});
const search = ref('');

const displayedProxies = computed(() => proxies.value
  .slice()
  .filter((encoder) => ['id', 'name', 'ip'].some(
    (key) => (String(encoder[key]).indexOf(search.value) !== -1),
  ))
  .sort(
    (a, b) => a.id - b.id,
  ));

function openModal(proxy) {
  modalForm.value = {
    id: proxy?.id,
    name: proxy?.name,
    ip: proxy?.ip,
    netRxBw: proxy?.netRxBw,
    netTxBw: proxy?.netTxBw,
    diskRxBw: proxy?.diskRxBw,
    diskTxBw: proxy?.diskTxBw,
  };
  modalVisible.value = true;
}

async function refreshList() {
  try {
    ({ data: proxies.value } = await myaxios.get('/proxies'));
  } catch (e) {
    // eslint-disable-next-line no-alert
    alert(`Couldn't download list ${see}`);
  }
}

async function submitModal() {
  console.log('submitting modal');
  try {
    if (modalForm.value.id) {
      // edit
      await myaxios.put(`/proxies/${modalForm.value.id}`, {
        name: modalForm.value.name,
        ip: modalForm.value.ip,
        netRxBw: modalForm.value.netRxBw,
        netTxBw: modalForm.value.netTxBw,
        diskRxBw: modalForm.value.diskRxBw,
        diskTxBw: modalForm.value.diskTxBw,
      });
    } else {
      // add
      await myaxios.post('/proxies', {
        name: modalForm.value.name,
        ip: modalForm.value.ip,
        netRxBw: modalForm.value.netRxBw,
        netTxBw: modalForm.value.netTxBw,
        diskRxBw: modalForm.value.diskRxBw,
        diskTxBw: modalForm.value.diskTxBw,
      });
    }
    modalVisible.value = false;
    await refreshList();
  } catch (e) {
    // eslint-disable-next-line no-alert
    if (e.code) alert(`${e.code} ${see}`);
    // eslint-disable-next-line no-alert
    else alert(`Server error ${see}`);
  }
}

async function del(proxy) {
  // eslint-disable-next-line no-restricted-globals,no-alert
  if (!confirm(`Are you sure you want to delete proxy ${proxy.name}?`)) return;
  try {
    await myaxios.delete(`/proxies/${proxy.id}`);
    await refreshList();
  } catch (e) {
    // eslint-disable-next-line no-alert
    if (e.code) alert(`${e.code} ${see}`);
    // eslint-disable-next-line no-alert
    else alert(`Server error ${see}`);
  }
}

refreshList();
</script>

<style lang="scss" scoped>
table.form th {
  text-align: right;
}
</style>
