import { createApp } from 'vue';
import { GlobalEvents } from 'vue-global-events';
import 'boxicons';
import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import Notifications from '@kyvg/vue3-notification';
import { GDialog } from 'gitart-vue-dialog';
import App from './App.vue';
import router from './router';
import store from './store';
import MyModal from './components/MyModal.vue';
import MyDate from './components/MyDate.vue';
import MyPagination from './components/MyPagination.vue';
import MyJsonDisplayer from './components/MyJsonDisplayer.vue';

import './assets/styles.scss';
import 'gitart-vue-dialog/dist/style.css';

dayjs.extend(calendar);

const app = createApp(App).use(store).use(Notifications).use(router);

app
  // global components
  .component('GlobalEvents', GlobalEvents)
  .component('MyModal', MyModal)
  .component('MyDate', MyDate)
  .component('MyPagination', MyPagination)
  .component('MyJsonDisplayer', MyJsonDisplayer)
  .component('GDialog', GDialog)

  // global directives
  .directive('focus', {
    mounted(el) {
      el.focus();
    },
  });

app.mount('#app');
