<template>
  <div>
    <div style="width: 30%">
      <h3>Add payment method</h3>
      <table class="list">
        <tbody>
          <tr>
            <th scope="row">name:</th>
            <td>
              <input type="text" v-model="method.name"/>
            </td>
          </tr>
          <tr>
            <th scope="row">validation_regex:</th>
            <td>
              <input type="text" v-model="method.validation_regex"/>
            </td>
          </tr>
          <tr>
            <th scope="row">minimum_amount:</th>
            <td>
              <input type="text" v-model="method.minimum_amount"/>
            </td>
          </tr>
          <tr>
            <th scope="row">fee:</th>
            <td>
              <input type="text" v-model="method.fee"/>
            </td>
          </tr>
          <tr>
            <th scope="row">is_crypto:</th>
            <td>
              <select v-model="method.is_crypto">
                <option v-for="option in options" :key="option.text" :value="option.value">
                  {{ option.text }}
                </option>
              </select>
            </td>
          </tr>
          <tr>
            <th scope="row">active:</th>
            <td>
              <select v-model="method.active">
                <option v-for="option in options" :key="option.text" :value="option.value">
                  {{ option.text }}
                </option>
              </select>
            </td>
          </tr>
          <tr>
            <td/>
            <td>
              <button @click="add_payment_method()">Add</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <h3>Payment methods</h3>
    <table v-if="methods.length" class="list">
      <thead>
        <tr>
          <th scope="col">name</th>
          <th scope="col">validation_regex</th>
          <th scope="col">fee</th>
          <th scope="col">minimum_amount</th>
          <th scope="col">is_crypto</th>
          <th scope="col">active</th>
          <th scope="col">actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in methods" v-bind:key="item.id">
          <td>{{ item.name }}</td>
          <td>{{ item.validation_regex }}</td>
          <td>{{ item.fee }}</td>
          <td>{{ item.minimum_amount }}</td>
          <td>{{ showText(item.is_crypto) }}</td>
          <td>{{ showText(item.active) }}</td>
          <td>
            <button @click="editMethod(item)">
              Edit
            </button>

            <GDialog v-model="dialog_state" max-width="60%">
              <table class="list">
                <tr>
                  <th scope="row">name:</th>
                  <td>
                    <input type="text" v-model="update_method.name"/>
                  </td>
                </tr>
                <tr>
                  <th scope="row">validation_regex:</th>
                  <td>
                    <input type="text" v-model="update_method.validation_regex"/>
                  </td>
                </tr>
                <tr>
                  <th scope="row">minimum_amount:</th>
                  <td>
                    <input type="text" v-model="update_method.minimum_amount"/>
                  </td>
                </tr>
                <tr>
                  <th scope="row">fee:</th>
                  <td>
                    <input type="number" step="0.1" max="10" min="0" v-model="update_method.fee"/>
                  </td>
                </tr>
                <tr>
                  <th scope="row">is_crypto:</th>
                  <td>
                    <select v-model="update_method.is_crypto">
                      <option v-for="option in options"
                              :key="option.text"
                              :value="option.value"
                              :selected="update_method.is_crypto === option.value">
                        {{ option.text }}
                      </option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <th scope="row">active:</th>
                  <td>
                    <select v-model="update_method.active">
                      <option v-for="option in options"
                              :key="option.text"
                              :value="option.value"
                              :selected="update_method.active === option.value">
                        {{ option.text }}
                      </option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <td/>
                  <td>
                    <button @click="update_the_payment_method(update_method.id)">Update</button>
                    <button @click="close_dialog()" style="margin-left: 20px">Cancel</button>
                  </td>
                </tr>
              </table>

            </GDialog>
          </td>
        </tr>
      </tbody>
    </table>
    <h3>Payouts</h3>
    <div>
      Status : <select v-model="query_status" @change="onQueryStatusChange()">
      <option></option>
      <option v-for="s in status" :key="s" :value="s">
        {{ s }}
      </option>
    </select>
    </div>
    <table v-if="payouts.length" class="list">
      <thead>
        <tr>
          <th scope="col">method</th>
          <th scope="col">amount</th>
          <th scope="col">payable</th>
          <th scope="col">user email</th>
          <th scope="col">receiver</th>
          <th scope="col">status</th>
          <th scope="col">message</th>
          <th scope="col">transaction_id</th>
          <th scope="col">created</th>
          <th scope="col">actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in payouts" v-bind:key="item.id">
          <td>{{ item.method }}</td>
          <td>{{ item.amount }}</td>
          <td>{{ item.payable }}</td>
          <td>{{ item.email }}</td>
          <td>{{ item.receiver }}</td>
          <td>{{ item.status }}</td>
          <td>{{ item.message }}</td>
          <td>{{ item.transaction_id }}</td>
          <td>{{ showDate(item.created) }}</td>
          <td>
            <div>
              status:
              <select v-model="update_payout_status[item.id]"
                      @change="onUpdatePayoutStatusChange()">
                <option v-for="s in status" :key="s" :value="s">
                  {{ s }}
                </option>
              </select>
            </div>
            <div v-if="update_payout_status[item.id] === 'REJECTED'
              || update_payout_status[item.id] === 'DELAYED'">
              message:
              <input type="text" v-model="update_payout_message[item.id]"/>
            </div>
            <div v-if="update_payout_status[item.id] === 'PAID'">
              transaction_id:
              <input type="text" v-model="update_payout_transaction_id[item.id]"/>
            </div>
            <button @click="update_the_payout_status(item.id)">Update</button>
          </td>
        </tr>
      </tbody>
    </table>

    <div v-if="payouts.length">
      Status:
      <select v-model="query_status" @change="onQueryStatusChange()">
        <option></option>
        <option v-for="s in status" :key="s" :value="s">
          {{ s }}
        </option>
      </select>
    </div>

    <my-pagination :pagination="pagination" @go-to-page="goToPage($event)" v-if="pagination"/>
  </div>
</template>

<script>
import * as dayjs from 'dayjs';
import myAxios from '../libs/myaxios';

export default {
  data() {
    return {
      methods: [],
      payouts: [],
      pagination: {},
      query_status: null,
      page: 1,
      method: {}, // Add
      update_method: {}, // Update
      update_payout_status: {},
      update_payout_message: {},
      update_payout_transaction_id: {},
      status: ['PENDING', 'REJECTED', 'PAID', 'DELAYED'],
      options: [
        { text: 'Yes', value: 1 },
        { text: 'No', value: 0 },
      ],
      dialog_state: false,
    };
  },
  methods: {
    editMethod(item) {
      this.update_method = item;
      this.dialog_state = true;
    },
    get_payouts() {
      myAxios.get('/payouts/get_payouts', {
        params: {
          status: this.query_status,
          page: this.$route.query.page,
        },
      }).then((response) => {
        this.payouts = response.data.items;
        this.pagination = response.data.pagination;
      }).catch((e) => {
        this.$notify({ type: 'error', text: e.code });
      });
    },
    add_payment_method() {
      const config = { headers: { 'Content-Type': 'application/json' } };
      myAxios.post('/payouts/add_payment_method', this.method, config).then(() => {
        this.$notify({ type: 'success', text: 'success' });
        this.get_payment_methods();
      }).catch((e) => {
        this.$notify({ type: 'error', text: e.code });
      });
    },
    update_the_payout_status(id) {
      const config = { headers: { 'Content-Type': 'application/json' } };
      myAxios
        .post(
          '/payouts/update_payout_status',
          {
            id,
            message: this.update_payout_message[id],
            transaction_id: this.update_payout_transaction_id[id],
            status: this.update_payout_status[id],
          },
          config,
        )
        .then(() => {
          this.$notify({ type: 'success', text: 'success' });
          this.get_payouts();
        }).catch((e) => {
          this.$notify({ type: 'error', text: e.code ?? 'server_error' });
        });
    },
    onUpdatePayoutStatusChange() {
      this.update_payout_message = {};
      this.update_payout_transaction_id = {};
    },
    showDate(unixSeconds) {
      return dayjs.unix(unixSeconds).calendar();
    },
    showText(val) {
      return val ? 'Yes' : 'No';
    },
    onQueryStatusChange() {
      this.get_payouts();
    },
    close_dialog() {
      this.dialog_state = false;
    },
    goToPage(page) {
      this.page = page;
      this.$router.push({
        path: '/payouts',
        query:
          {
            page,
          },
      });
    },
    get_payment_methods() {
      myAxios.get('/payouts/get_payment_methods').then((response) => {
        this.methods = response.data;
      }).catch((e) => {
        this.$notify({ type: 'error', text: e.code });
      });
    },
    update_the_payment_method(id) {
      const config = { headers: { 'Content-Type': 'application/json' } };
      myAxios
        .post(
          '/payouts/update_payment_method',
          {
            id,
            name: this.update_method.name,
            validation_regex: this.update_method.validation_regex,
            fee: this.update_method.fee,
            minimum_amount: this.update_method.minimum_amount,
            is_crypto: this.update_method.is_crypto,
            active: this.update_method.active,
          },
          config,
        )
        .then(() => {
          this.$notify({ type: 'success', text: 'success' });
          this.get_payment_methods();
          this.close_dialog();
        }).catch((e) => {
          this.$notify({ type: 'error', text: e.code ?? 'server_error' });
        });
    },
  },
  created() {
    this.$watch('dialog_state', () => {
      this.get_payment_methods();
    });
    this.$emit('update:layout', 'default');
    this.get_payment_methods();
    this.get_payouts();
  },
  watch: {
    $route() {
      this.get_payouts();
    },

  },
};
</script>

<style>
.dialog {
  padding: 30px 20px;
}

</style>
