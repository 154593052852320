<template>
  <div>
    <h3>Encoders</h3>

    <p>
      Refresh interval:
      <input type="range" min="2" max="10" v-model="refreshInterval">
      {{ refreshInterval }} secs
    </p>

    <SortableTable :rows="fullServers" :fields="[
      ['id', 'ID'],
      ['mnemonic_name', 'Mnemonic name'],
      ['currentUploads', 'Current uploads'],
      ['jobsQueued', 'Jobs queued'],
      ['totalJobs', 'Total jobs'],
    ]"/>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useIntervalFn } from '@vueuse/core';
import myaxios from '../libs/myaxios';
import SortableTable from '@/components/SortableTable.vue';

// eslint-disable-next-line no-undef
const emit = defineEmits(['update:layout']);
emit('update:layout', 'default');

const servers = ref([]);
const encoderData = ref({});
const refreshInterval = ref(5);

const fullServers = computed(() => servers.value.map((server) => {
  const ret = {
    ...server, currentUploads: null, jobsQueued: null, totalJobs: null,
  };
  const ed = encoderData.value[server.mnemonic_name];
  if (ed) {
    ret.currentUploads = ed.currentUploads;
    ret.jobsQueued = ed.jobsQueued;
    ret.totalJobs = ed.currentUploads + ed.jobsQueued;
  }
  return ret;
}));

async function getServers() {
  try {
    ({ data: servers.value } = await myaxios.get('/servers', { params: { type: 'ENCODER' } }));
  } catch (err) {
    // eslint-disable-next-line no-alert
    alert("Couldn't fetch servers list");
  }
}

async function getEncoderData() {
  try {
    ({ data: encoderData.value } = await myaxios.get('/encoders'));
  } catch (err) {
    // eslint-disable-next-line no-alert
    alert("Couldn't fetch encoder data");
  }
}

useIntervalFn(getEncoderData, computed(() => refreshInterval.value * 1_000), {
  immediateCallback: true,
});
getServers();
</script>
