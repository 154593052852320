<template>
  <div class="page">
    <div class="square">
      <h2>Login</h2>

      <form @submit.prevent="submit">
        <table>
          <tr>
            <th>Username:</th>
            <td><input type="text" v-model="username" /></td>
          </tr>
          <tr>
            <th>Password:</th>
            <td><input type="password" v-model="password" /></td>
          </tr>
          <tr>
            <th>2FA:</th>
            <td><input type="text" v-model="tfa" /></td>
          </tr>
          <tr>
            <td />
            <td><button type="submit">Log in</button></td>
          </tr>
        </table>
      </form>
<!--      <router-link to="/">Overview</router-link>-->
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import myaxios from '../libs/myaxios';
import auth from '../libs/auth';

// eslint-disable-next-line no-undef
const emit = defineEmits(['update:layout']);
emit('update:layout', 'empty');

const username = ref('');
const password = ref('');
const tfa = ref('');

const router = useRouter();

async function submit() {
  try {
    await myaxios.post('/auth/login', {
      username: username.value,
      password: password.value,
      tfa: tfa.value,
    });
    auth.hasCalledAuthenticate = false;
    await router.push('/');
  } catch (e) {
    if (e.status === 401) {
      // eslint-disable-next-line no-alert
      alert(e.code);
    } else {
      // eslint-disable-next-line no-alert
      alert('Server error');
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
}

h2 {
  text-align: center;
  background-color: #ccc;
}

table th {
  text-align: right;
}
</style>
