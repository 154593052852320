<template>
  <div>
    <h3>Files</h3>

    <table class="list">
      <tr>
        <th>ID</th>
        <th>Name</th>
        <th>Email</th>
        <th>Size</th>
        <th>Created</th>
        <th>Status</th>
      </tr>
      <tr v-for="file in files" :key="file.id">
        <td style="text-align: center;">{{ file.id }}</td>
        <td>{{ file.name }}</td>
        <td>{{ file.email }}</td>
        <td>{{ fileSize(file.size) }}</td>
        <td>
          <my-date :epoch="file.created" format="full" />
        </td>
        <td>
          <template v-if="file.status">
            {{ file.status }}
            <template v-if="file.progress !== null">
              - {{ +file.progress.toFixed(1) }}%
            </template>
          </template>
          <span v-else style="color: #0a0;">READY</span>
        </td>
      </tr>
    </table>

    <my-pagination :pagination="pagination" @go-to-page="goToPage($event)" v-if="pagination" />
  </div>
</template>

<script setup>
import { ref, watchEffect } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { fileSize } from 'humanize-plus';
import { useSubscription, from } from '@vueuse/rxjs';
import { interval } from 'rxjs';
import {
  map, filter, exhaustMap, takeWhile, switchMap,
} from 'rxjs/operators';
import myaxios from '../libs/myaxios';

// eslint-disable-next-line no-undef
const emit = defineEmits(['update:layout']);
emit('update:layout', 'default');

const see = '- visit network inspector in browser devtools for more';

const route = useRoute();

const files = ref([]);
const pagination = ref(null);

async function fetchFiles() {
  try {
    ({
      data: {
        items: files.value,
        pagination: pagination.value,
      },
    } = await myaxios.get('/files', {
      params: { page: route.query.page },
    }));
  } catch (e) {
    // eslint-disable-next-line no-alert
    alert(`Couldn't download list ${see}`);
  }
}

watchEffect(() => { fetchFiles(); });

const router = useRouter();
async function goToPage(page) {
  await router.push({ path: '/files', query: { page } });
}

// update statuses every second (or so), starting from every page change
// until all files on the page have status = ready (i.e. null)
useSubscription(
  from(pagination).pipe(
    filter((pp) => pp?.page != null),
    switchMap(() => interval(1000).pipe(
      map(() => files.value.filter((file) => ![null, 'ERROR'].includes(file.status))),
      // if 0 files have non-null status, end loop
      takeWhile((someFiles) => someFiles.length),
      exhaustMap((someFiles) => from(
        myaxios.get('/files/statuses', {
          params: { id: someFiles.map((file) => file.id) },
        }).then(
          (resp) => resp.data,
          () => ({}),
        ),
      )),
      filter((o) => !!Object.keys(o).length),
    )),
  ).subscribe((idsToStatus) => {
    files.value.forEach((file) => {
      if (file.id in idsToStatus) {
        Object.assign(file, idsToStatus[file.id]);
      }
    });
  }),
);
</script>
