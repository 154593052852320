// eslint-disable-next-line import/prefer-default-export
export function dclone(thing) {
  return JSON.parse(JSON.stringify(thing));
}

export function hashify(array, fields, mapFn) {
  // param validation
  if (!fields.length) throw new Error('fields empty');

  const ret = {};
  for (let i = 0; i < array.length; i += 1) {
    const item = array[i];
    let cursor = ret;
    let fieldName = fields[0];
    let fieldValue = item[fieldName];
    for (let j = 0; j < fields.length - 1; j += 1) {
      cursor[fieldValue] ??= {};
      cursor = cursor[fieldValue];
      fieldName = fields[j + 1];
      fieldValue = item[fieldName];
    }
    cursor[fieldValue] = mapFn ? mapFn(item) : item;
  }

  return ret;
}
