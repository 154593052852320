<template>
  <div>
    <h3>Uploads & Jobs</h3>

    <table class="list">
      <tr>
        <th>ID</th>
        <th>Encode server</th>
        <th>e-mail</th>
        <th>Created</th>
        <th>Status</th>
        <th>Jobs</th>
        <th>Source</th>
      </tr>
      <tr v-for="upload in uploads" :key="upload.id">
        <td>{{ upload.id }}</td>
        <td>{{ upload.encoder }}</td>
        <td>{{ upload.email }}</td>
        <td>
          <my-date :epoch="upload.added" format="full" />
        </td>
        <td>
          <template v-if="upload.status">
            {{ upload.status }}
            <template v-if="upload.progress !== null">
              - {{ +upload.progress.toFixed(1) }}%
            </template>
          </template>
          <span v-else style="color: #0a0;">READY</span>
        </td>
        <td>
          <template v-for="(jobId, idx) in upload.jobIds" :key="jobId">
            <span :class="{ 'job-hidden': jobStatuses[jobId] === undefined }">
              <span class="job-id" :class="jobStatuses[jobId]" @click="openJob(jobId)">
                {{ jobId }}
              </span>
              <template v-if="idx < upload.jobIds.length - 1">, </template>
            </span>
          </template>
        </td>
        <td>{{ upload.source }}</td>
      </tr>
    </table>

    <my-pagination :pagination="pagination" @go-to-page="goToPage($event)" v-if="pagination" />

    <my-modal v-if="modalVisible">
      <global-events @keydown.esc="modalVisible = false" />

      <template #header>Job #{{ modalData.jobId }}</template>

      <template #footer>
        <button @click="modalVisible = false">Close</button>&#x200b;
      </template>

      <div v-if="!modalData.jobId">loading...</div>
      <div v-else>
        <my-json-displayer :thing="modalData.info" />
      </div>
    </my-modal>
  </div>
</template>

<script setup>
import { ref, watchEffect } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { from, useSubscription } from '@vueuse/rxjs/index';
import {
  exhaustMap, filter, map, switchMap, takeWhile, tap,
} from 'rxjs/operators';
import { interval, timer } from 'rxjs';
import myaxios from '../libs/myaxios';

// eslint-disable-next-line no-undef
const emit = defineEmits(['update:layout']);
emit('update:layout', 'default');

const see = '- visit network inspector in browser devtools for more';

const route = useRoute();

const uploads = ref([]);
const pagination = ref(null);
const jobStatuses = ref({});
const modalVisible = ref(false);
const modalData = ref({});

async function fetchUploads() {
  try {
    ({
      data: {
        items: uploads.value,
        pagination: pagination.value,
      },
    } = await myaxios.get('/uploads', {
      params: { page: route.query.page },
    }));
  } catch (e) {
    // eslint-disable-next-line no-alert
    alert(`Couldn't download list ${see}`);
  }
}

watchEffect(() => { fetchUploads(); });

const router = useRouter();
async function goToPage(page) {
  await router.push({ path: '/uploads', query: { page } });
}

// update statuses every second (or so), starting from every page change
// until all files on the page have status = ready (i.e. null)
useSubscription(
  from(pagination).pipe(
    filter((pp) => pp?.page != null),
    switchMap(() => interval(1000).pipe(
      map(() => uploads.value.filter((upload) => !['SUCCESS', 'ERROR'].includes(upload.status))),
      // if 0 files have non-null status, end loop
      takeWhile((someUploads) => someUploads.length),
      exhaustMap((someUploads) => from(
        myaxios.get('/uploads/statuses', {
          params: { id: someUploads.map((upload) => upload.id) },
        }).then(
          (resp) => resp.data,
          () => ({}),
        ),
      )),
      filter((o) => !!Object.keys(o).length),
    )),
  ).subscribe((idsToStatus) => {
    uploads.value.forEach((upload) => {
      if (upload.id in idsToStatus) {
        Object.assign(upload, idsToStatus[upload.id]);
      }
    });
  }),
);

// update job statuses and colors every second
useSubscription(
  from(pagination).pipe(
    tap(() => { jobStatuses.value = {}; }),
    filter((pp) => pp?.page != null),
    switchMap(() => timer(250, 1e3).pipe(
      map(
        () => uploads.value
          .flatMap((u) => u.jobIds)
          .filter((jid) => !['failed', 'finished', 'missing'].includes(jobStatuses.value[jid])),
      ),
      takeWhile((unresolvedJids) => unresolvedJids.length),
      exhaustMap((unresolvedJids) => from(
        myaxios.post('/uploads/get_job_states', { jids: unresolvedJids })
          .then(
            (resp) => resp.data,
            () => ({}),
          ),
      )),
      filter((o) => !!Object.keys(o).length),
    )),
  ).subscribe((idsToState) => {
    Object.assign(jobStatuses.value, idsToState);
  }),
);

async function openJob(jobId) {
  if (jobStatuses.value[jobId] === 'missing') return;

  modalData.value = {};
  modalVisible.value = true;
  try {
    const { data } = await myaxios.get(`/uploads/job_info/${jobId}`);
    modalData.value = {
      jobId,
      info: data,
    };
  } catch (e) {
    // eslint-disable-next-line no-alert
    alert(`Couldn't download job info ${see}`);
  }
}
</script>

<style lang="scss" scoped>
@use "sass:color";

$missing: #bbb;
$failed: red;
$finished: green;
$active: blue;
$inactive: #bbb;
$highlight: 15%;

table.list {
  font-size: smaller;
}

.job-hidden {
  visibility: hidden;
}

.job-id {
  &.missing {
    text-decoration: line-through;
    color: $missing;
  }

  &.failed {
    color: $failed;
    &:hover {
      color: color.scale($failed, $lightness: $highlight, $saturation: $highlight);
    }
  }

  &.finished {
    color: $finished;
    &:hover {
      color: color.scale($finished, $lightness: $highlight, $saturation: $highlight);
    }
  }

  &.active {
    color: $active;
    &:hover {
      color: color.scale($active, $lightness: $highlight, $saturation: $highlight);
    }
  }

  &.inactive {
    color: $inactive;
    &:hover {
      color: color.scale($inactive, $lightness: $highlight, $saturation: $highlight);
    }
  }

  &.failed, &.finished, &.active, &.inactive {
    cursor: pointer;
  }
}
</style>
