import { createRouter, createWebHistory } from 'vue-router';
import auth from '../libs/auth';
import Login from '../views/Login.vue';
import RegUser from '../views/RegUser.vue';
import Overview from '../views/Overview.vue';
import Files from '../views/Files.vue';
import Encoders from '../views/Encoders.vue';
import Minios from '../views/Minios.vue';
import Users from '../views/Users.vue';
import Uploads from '../views/Uploads.vue';
import Claims from '../views/Claims.vue';
import Payouts from '../views/Payouts.vue';
import Proxies from '../views/Proxies.vue';
import Settings from '../views/Settings.vue';
import Support from '../views/Support.vue';
import SupportTicket from '../views/SupportTicket.vue';
import EncoderLb from '../views/EncoderLb.vue';
import HappyHour from '../views/HappyHour.vue';
import Servers from '../views/Servers.vue';

// meta.auth shows whether the user needs to be logged-in (true), logged-off (false) or
// doesn't matter (null). Default is true.

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { auth: false },
  },
  {
    path: '/reg-user',
    name: 'RegUser',
    component: RegUser,
    meta: { auth: false },
  },
  {
    path: '/',
    name: 'Overview',
    component: Overview,
  },
  {
    path: '/files',
    name: 'Files',
    component: Files,
  },
  {
    path: '/encoders',
    name: 'Encoders',
    component: Encoders,
  },
  {
    path: '/minios',
    name: 'Minios',
    component: Minios,
  },
  {
    path: '/users',
    name: 'Users',
    component: Users,
  },
  {
    path: '/uploads',
    name: 'Uploads',
    component: Uploads,
  },
  {
    path: '/dmca',
    name: 'Claims',
    component: Claims,
  },
  {
    path: '/payouts',
    name: 'Payouts',
    component: Payouts,
  },
  {
    path: '/proxies',
    name: 'Proxies',
    component: Proxies,
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
  },
  {
    path: '/support',
    name: 'Support',
    component: Support,
  },
  {
    path: '/support/ticket/:id',
    name: 'SupportTicket',
    component: SupportTicket,
  },
  {
    path: '/encoder_lb',
    name: 'EncoderLb',
    component: EncoderLb,
  },
  {
    path: '/happy_hour',
    name: 'HappyHour',
    component: HappyHour,
  },
  {
    path: '/servers',
    name: 'Servers',
    component: Servers,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to) => {
  let requireAuth = to.meta?.auth;
  if (requireAuth === undefined) requireAuth = true;

  if (requireAuth === null) return true;
  if (!auth.hasCalledAuthenticate) await auth.authenticate();
  if (requireAuth === !!auth.username) return true;
  if (requireAuth && !auth.username) {
    console.log('route requires authentication, but user is unauthenticated');
    return '/login';
  }
  console.log('route requires user to be unauthenticated, but is authenticated');
  return '/';
});

export default router;
