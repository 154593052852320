<template>
  <notifications />
  <component :is="layouts[layout]">
    <router-view v-model:layout="layout" />
  </component>
</template>

<script setup>
import { ref } from 'vue';
import Empty from './layouts/Empty.vue';
import Default from './layouts/Default.vue';

const layouts = {
  empty: Empty,
  default: Default,
};
const layout = ref('empty');
</script>
