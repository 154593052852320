import { reactive } from 'vue';
import myaxios from './myaxios';

const auth = reactive({
  username: null,
  hasCalledAuthenticate: false,
  async authenticate() {
    try {
      const { data: username } = await myaxios.get('/auth/who_am_i');
      auth.username = username;
      auth.hasCalledAuthenticate = true;
      return true;
    } catch (e) {
      if (e.status === 401) {
        auth.username = null;
        auth.hasCalledAuthenticate = true;
        return false;
      }
      throw e;
    }
  },
});

export default auth;

export async function getScriptPassword() {
  try {
    const { data: { scriptPassword, mode, scriptDir } } = await myaxios.get('/auth/script_password');
    return [scriptPassword, mode, scriptDir];
  } catch (e) {
    // eslint-disable-next-line no-alert
    alert("Couldn't get script password");
    throw new Error('Could not get script password');
  }
}
