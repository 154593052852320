<template>
  <h3>Support</h3>

  <p>
    Search by status:
    <select v-model="desiredStatus">
      <option :value="null">resolved &amp; unresolved</option>
      <option>unresolved</option>
      <option>resolved</option>
    </select>
    and category:
    <select v-model="desiredCategory">
      <option :value="null">All</option>
      <option v-for="cat in sortedCats" :key="cat.id" :value="cat.id">{{ cat.name }}</option>
    </select>
    &nbsp;
    <button @click="goToPage(1)">Search</button>
  </p>

  <table class="list">
    <tr>
      <th>ID</th>
      <th>e-mail</th>
      <th>Category</th>
      <th>Subject</th>
      <th>Status</th>
      <th>Created</th>
      <th>Updated</th>
    </tr>
    <tr v-for="ticket in tickets" :key="ticket.id"
        class="clickable" @click="$router.push(`/support/ticket/${ticket.id}`)">
      <td>{{ ticket.id }}</td>
      <td>{{ ticket.email }}</td>
      <td>{{ ticket.catName }}</td>
      <td>{{ ticket.subject }}</td>
      <td>{{ ticket.status }}</td>
      <td>{{ showDate(ticket.epoch) }}</td>
      <td>{{ showDate(ticket.updated) }}</td>
    </tr>
  </table>

  <p>
    {{ pagination.totalEntries }} total entries<br>
    <a @click="goToPage(pagination.prevPage)" v-if="pagination.prevPage">«</a>
    <template v-if="pagination.prevPage && pagination.nextPage">-</template>
    <a @click="goToPage(pagination.nextPage)" v-if="pagination.nextPage">»</a>
  </p>
</template>

<script setup>
import { ref, computed } from 'vue';
import dayjs from 'dayjs';
import myaxios from '../libs/myaxios';

// eslint-disable-next-line no-undef
const emit = defineEmits(['update:layout']);
emit('update:layout', 'default');

const desiredStatus = ref('unresolved');
const desiredCategory = ref(null);
const cats = ref([]);
const tickets = ref([]);
const pagination = ref({});
const page = ref(1);

const sortedCats = computed(() => {
  const myCats = cats.value.slice();
  myCats.sort((a, b) => (a.visible ? 0 : 1) - (b.visible ? 0 : 1));
  return myCats;
});
async function getAllCats() {
  ({ data: cats.value } = await myaxios.get('/support/cats'));
}

async function goToPage(pageNum) {
  page.value = pageNum;
  ({ data: { rows: tickets.value, pagination: pagination.value } } = await myaxios.get('/support/tickets', {
    params: {
      status: desiredStatus.value,
      cat_id: desiredCategory.value,
      page: page.value,
      // rpp: 1,
    },
  }));
}

function showDate(epoch) {
  return dayjs.unix(epoch).format('YYYY-MM-DD HH:mm');
}

getAllCats();
</script>
