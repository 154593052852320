<template>
  <h2>Support Ticket #{{ id }}</h2>

  <p>Status: {{ ticket.status }}</p>

  <div class="block">
    <strong>Subject: {{ ticket.subject }}</strong><br>
    Date: {{ showDate(ticket.epoch) }}
    <hr>
    <pre>{{ ticket.body }}</pre>
  </div>

  <div class="block" v-for="response in ticket.responses" :key="response.id">
    <strong>From: {{ response.fromUs ? 'Admin' : ticket.email }}</strong><br>
    Date: {{ showDate(response.epoch) }}
    <hr>
    <pre>{{ response.body }}</pre>
  </div>

  <h4 style="margin-bottom: 0;">Respond to ticket:</h4>
  <textarea v-model="body" cols="60" rows="10"></textarea><br>
  <button @click="submit">Submit</button>

  <div style="text-align: right;" v-if="ticket.status === 'unresolved'">
    <button style="color: red;" @click="resolve">Close this ticket</button>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import dayjs from 'dayjs';
import myaxios from '../libs/myaxios';

// eslint-disable-next-line no-undef
const emit = defineEmits(['update:layout']);
emit('update:layout', 'default');

const route = useRoute();
const { id } = route.params;

const ticket = ref({});
const body = ref('');

async function getTicket() {
  ({ data: ticket.value } = await myaxios.get(`/support/ticket/${id}`));
}

function showDate(epoch) {
  return dayjs.unix(epoch).format('YYYY-MM-DD HH:mm');
}

async function submit() {
  await myaxios.post(`/support/ticket/${id}`, {
    body: body.value,
  });
  body.value = '';
  await getTicket();
}

async function resolve() {
  // eslint-disable-next-line no-restricted-globals,no-alert
  if (!confirm('Are you sure you want to close this ticket?')) return;
  await myaxios.post(`/support/ticket/${id}/resolve`);
  await getTicket();
}
getTicket();
</script>

<style lang="scss" scoped>
.block {
  border: 2px solid black;
  border-radius: 8px;
  padding: 8px;
}

.block + .block {
  margin-top: 15px;
}
</style>
