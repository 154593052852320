import axios from 'axios';

class MyAxios {
  constructor() {
    this.myAxiosInstance = axios.create({ baseURL: '/api' });
  }

  async myQuery(method, ...args) {
    try {
      return await this.myAxiosInstance[method](...args);
    } catch (err) {
      const res = err.response;
      if (res.status === 401 && res.data?.code === 'not_authenticated') {
        // eslint-disable-next-line no-alert
        alert('Not authenticated. Please visit the login page, or /');
      }
      // eslint-disable-next-line no-throw-literal
      throw {
        status: res.status,
        code: res.data.code,
        data: res.data.data,
      };
    }
  }

  get(...args) {
    return this.myQuery('get', ...args);
  }

  post(...args) {
    return this.myQuery('post', ...args);
  }

  put(...args) {
    return this.myQuery('put', ...args);
  }

  delete(...args) {
    return this.myQuery('delete', ...args);
  }

  patch(...args) {
    return this.myQuery('patch', ...args);
  }
}

export default new MyAxios();
