<template>
  <pre v-html="syntaxHighlight(JSON.stringify(props.thing, null, 4))"></pre>
</template>

<script setup>
// eslint-disable-next-line no-undef
const props = defineProps({
  thing: { required: true },
});

// source: https://www.exeideas.com/2021/11/pretty-print-json-data-in-color.html
function syntaxHighlight(json) {
  // eslint-disable-next-line no-param-reassign
  json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
  return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g, (match) => {
    let cls = 'number';
    if (/^"/.test(match)) {
      if (/:$/.test(match)) {
        cls = 'key';
      } else {
        cls = 'string';
      }
    } else if (/true|false/.test(match)) {
      cls = 'boolean';
    } else if (/null/.test(match)) {
      cls = 'null';
    }
    return `<span class="${cls}">${match}</span>`;
  });
}
</script>

<style lang="scss" scoped>
  ::v-deep(.string) { color: green; }
  ::v-deep(.number) { color: darkorange; }
  ::v-deep(.boolean) { color: blue; }
  ::v-deep(.null) { color: magenta; }
  ::v-deep(.key) { color: red; }
</style>
