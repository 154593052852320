<template>
  <div class="pagination-top">
    <div v-if="pagination.page !== 1">
      <box-icon name="chevrons-left" type="solid" size="md"
                @click="goTo(1)" class="clickable" />
    </div>
    <div v-else>
      <box-icon name="chevrons-left" type="solid" size="md" color="#ccc" />
    </div>

    <div v-if="pagination.prev !== null">
      <box-icon name="chevron-left" type="solid" size="md"
                @click="goTo(pagination.prev)" class="clickable" />
    </div>
    <div v-else>
      <box-icon name="chevron-left" type="solid" size="md" color="#ccc" />
    </div>

    <div>
      Page {{ pagination.page }} of {{ pagination.lastPage }}
      (showing {{ pagination.first }}-{{ pagination.last }} of {{ pagination.total }})
    </div>

    <div v-if="pagination.next !== null">
      <box-icon name="chevron-left" flip="horizontal" type="solid" size="md"
                @click="goTo(pagination.next)" class="clickable" />
    </div>
    <div v-else>
      <box-icon name="chevron-left" flip="horizontal" type="solid" size="md" color="#ccc" />
    </div>

    <div v-if="pagination.lastPage !== pagination.page">
      <box-icon name="chevrons-right" type="solid" size="md"
                @click="goTo(pagination.lastPage)" class="clickable" />
    </div>
    <div v-else>
      <box-icon name="chevrons-right" type="solid" size="md" color="#ccc" />
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';

// eslint-disable-next-line no-undef
const emit = defineEmits(['goToPage']);

// eslint-disable-next-line no-undef
const props = defineProps({
  pagination: {
    type: Object,
    required: true,
  },
});

const pagination = computed(() => props.pagination);

function goTo(page) {
  emit('goToPage', page);
}
</script>

<style lang="scss" scoped>
.pagination-top {
  margin: 18px 0;

  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
</style>
