<template>
  <div class="page">
    <div class="square">
      <h2>Complete registration for new Admin user</h2>

      <form @submit.prevent="submit" v-if="ticketIsValid">
        <table>
          <tr>
            <th>Username:</th>
            <td>
              <input type="text" v-model="username" disabled />
            </td>
          </tr>
          <tr>
            <th>Password:</th>
            <td>
              <input type="password" v-model="password" />
            </td>
          </tr>
          <tr>
            <th>Repeat password:</th>
            <td>
              <input type="password" v-model="repeatPassword" />
            </td>
          </tr>
          <tr>
            <th />
            <td>
              <img v-if="qrText" :src="qrCode" alt="QR code" />
            </td>
          </tr>
          <tr>
            <th>2FA verification:</th>
            <td>
              <input type="text" v-model="qrVerification" />
            </td>
          </tr>
          <tr>
            <th />
            <td>
              <button type="submit" :disabled="!inputIsValid">
                Submit
              </button>
            </td>
          </tr>
        </table>
      </form>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useQRCode } from '@vueuse/integrations/useQRCode';
import myaxios from '../libs/myaxios';

// eslint-disable-next-line no-undef
const emit = defineEmits(['update:layout']);
emit('update:layout', 'empty');

const route = useRoute();
const router = useRouter();

const { code } = route.query;
const ticketIsValid = ref(null);
const username = ref('');
const password = ref('');
const repeatPassword = ref('');
const qrText = ref('');
const qrCode = useQRCode(qrText);
const qrVerification = ref('');

(async () => {
  try {
    await myaxios.get('/auth/validate-reg-ticket', {
      params: { code },
    });
    ticketIsValid.value = true;
  } catch (e) {
    if (e.status === 404 && e.code === 'ticket_not_found') {
      ticketIsValid.value = false;
      // eslint-disable-next-line no-alert
      alert('Link code not found or expired');
    } else {
      // eslint-disable-next-line no-alert
      alert('Server error');
    }
  }
})();

(async () => {
  ({
    data: {
      otpauth: qrText.value,
      username: username.value,
    },
  } = await myaxios.post('/auth/generate-2fa-secret', { code }));
})();

const inputIsValid = computed(() => password.value === repeatPassword.value
  && password.value.length >= 8
  && qrVerification.value.length === 6);

async function submit() {
  try {
    await myaxios.post('/auth/submit-reg-user', {
      code,
      password: password.value,
      qr_verification: qrVerification.value,
    });
    await router.push('/login');
    // eslint-disable-next-line no-alert
    alert('Thank you!');
  } catch (e) {
    // eslint-disable-next-line no-alert
    alert('Some error occurred - check the Networking dev tools for more');
  }
}
</script>

<style lang="scss" scoped>
.page {
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
}

h2 {
  text-align: center;
  background-color: #ccc;
}

table th {
  text-align: right;
}
</style>
