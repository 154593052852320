<template>
  <div>
    <h3>Encoder Load Balancing</h3>

    <table class="list">
      <tr>
        <th>Name</th>
        <th>Jobs queued</th>
        <th>Future jobs</th>
        <th>Total jobs</th>
      </tr>
      <tr v-for="encoder in sortedEncoders" :key="encoder.name">
        <td>{{ encoder.name }}</td>
        <td>{{ encoder.jobsQueued }}</td>
        <td>{{ encoder.futureJobs }}</td>
        <td>{{ encoder.totalJobs }}</td>
      </tr>
    </table>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import myaxios from '../libs/myaxios';

// eslint-disable-next-line no-undef
const emit = defineEmits(['update:layout']);
emit('update:layout', 'default');

const encoders = ref([]);
const sortedEncoders = computed(() => encoders.value.slice().sort((a, b) => {
  if (a.totalJobs === b.totalJobs) {
    return a.name.localeCompare(b.name);
  }
  return a.totalJobs - b.totalJobs;
}));

async function fetchEncoders() {
  try {
    ({ data: encoders.value } = await myaxios.get('/encoder_lb/stats'));
  } catch (e) {
    // eslint-disable-next-line no-alert
    alert("Couldn't retrieve encoders load balancing statistics.");
  }
}

fetchEncoders();
</script>
