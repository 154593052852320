<template>
  <div>
    <h3>Happy hour</h3>

    <p>
      Current setting:
      <span v-if="setting[1] === 0">No happy hour</span>
      <span v-else>
        +{{ setting[1] }}%
        until
        {{ new Date(setting[0] * 1e3) }}
      </span>
    </p>

    <form @submit.prevent="submit">
      <fieldset>
        <legend>Set happy hour</legend>
        <p>
          % increase in payout:
          <input type="number" v-model.number="pct"
                 min="1" max="100" step="1">%
        </p>
        <p>
          <input type="radio" v-model="which" value="duration">
          Duration:
          <input type="number" v-model.number="durHours" placeholder="No. of hours"
            :disabled="which !== 'duration'"
          >
          hours
        </p>
        <p>
          <input type="radio" v-model="which" value="expirationEpoch">
          Expiration time:
          <input type="datetime-local" v-model="expDt" :disabled="which !== 'expirationEpoch'">
          UTC
        </p>

        <p>
          <button type="submit">Submit</button>
        </p>
      </fieldset>
    </form>

    <p>
      <button class="delete" @click="deleteHappyHour">Delete happy hour</button>
    </p>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import myaxios from '../libs/myaxios';

// eslint-disable-next-line no-undef
const emit = defineEmits(['update:layout']);
emit('update:layout', 'default');

const setting = ref([null, 0]);
const which = ref('duration');
const durHours = ref(2);
const expDt = ref(null);
const pct = ref(50);

async function getSetting() {
  try {
    ({ data: setting.value } = await myaxios.get('/happy_hour/setting'));
  } catch (e) {
    // eslint-disable-next-line no-alert
    alert("Couldn't get happy hour setting");
  }
}

async function submit() {
  try {
    await myaxios.post('/happy_hour/setting', {
      which: which.value,
      durHours: durHours.value,
      expDt: expDt.value,
      pct: pct.value,
    });
  } catch (e) {
    // eslint-disable-next-line no-alert
    alert('Error in submission of happy hours setting');
    return;
  }
  await getSetting();
  // eslint-disable-next-line no-alert
  alert('Success!');
}

async function deleteHappyHour() {
  // eslint-disable-next-line no-restricted-globals,no-alert
  if (!confirm('Are you sure you want to remove Happy Hour?')) return;

  try {
    await myaxios.delete('/happy_hour/setting');
  } catch (e) {
    // eslint-disable-next-line no-alert
    alert('Error in deleting happy hour');
    return;
  }

  await getSetting();
  // eslint-disable-next-line no-alert
  alert('Success!');
}

getSetting();
</script>

<style lang="scss" scoped>
button.delete {
  background-color: red;
  color: white;
  font-weight: bold;
}
</style>
