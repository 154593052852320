<template>
  {{ output }}
</template>

<script setup>
import { computed } from 'vue';
import dayjs from '../libs/mydayjs';

const formats = {
  full: 'ddd, MMM Do YYYY, HH:mm:ss z',
};

// eslint-disable-next-line no-undef
const props = defineProps({
  epoch: {
    type: Number,
    required: true,
    validator: (value) => value > 0,
  },
  format: {
    type: String,
    required: true,
    validator: (value) => ['full'].includes(value),
  },
});

const d = computed(() => dayjs.unix(props.epoch));
const output = computed(() => d.value.format(formats[props.format]));
</script>
