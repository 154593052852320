<template>
  <div>
    <h3>DMCA Claims</h3>
    <table v-if="claims.length" class="list">
      <tr>
        <th scope="col">code</th>
        <th scope="col">info</th>
        <!--<th scope="col">dmca_videos</th>-->
        <th scope="col">status</th>
        <th scope="col">action</th>
      </tr>
      <tr v-for="item in claims" v-bind:key="item.code">
        <td>
          {{ item.code }}
        </td>
        <td>
          <div v-if="expanded[item.code]">
            <div><span @click="toggleClaim(item.code)">[collapse]</span></div>
            <vue-json-pretty :data="item.data" />
            <div><span @click="toggleClaim(item.code)">[collapse]</span></div>
          </div>
          <div v-else>
            <div><span @click="toggleClaim(item.code)">[expand]</span></div>
          </div>
        </td>
        <td>{{ item.status }}</td>
        <td>
          <button @click="processClaim(item.code)" disabled>Process</button>
          <button @click="holdClaim(item.code)" disabled>Hold</button>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
import myAxios from '../libs/myaxios';

export default {
  data() {
    return {
      claims: [],
      expanded: {},
    };
  },
  methods: {
    toggleClaim(code) {
      if (code in this.expanded) delete this.expanded[code];
      else this.expanded[code] = true;
    },
    get_claims() {
      const that = this;
      myAxios.get('/dmca/claims').then((response) => {
        that.claims = response.data;
      });
    },
    processClaim() {
      // const config = { headers: { 'Content-Type': 'application/json' } };
      // myAxios.post(`/dmca/claim/${code}`, { action: 'process' }, config).then(() => {
      //   this.get_claims();
      // });
    },
    holdClaim() {
      // const config = { headers: { 'Content-Type': 'application/json' } };
      // myAxios.post(`/dmca/claim/${code}`, { action: 'hold' }, config).then(() => {
      //   this.get_claims();
      // });
    },
  },
  created() {
    this.get_claims();
  },
  components: { VueJsonPretty },
};
</script>
<style lang="scss" scoped>
@use "sass:color";

$missing: #bbb;
$failed: red;
$finished: green;
$active: blue;
$inactive: #bbb;
$highlight: 15%;

table.list {
  font-size: smaller;
}

.job-hidden {
  visibility: hidden;
}

.job-id {
  &.missing {
    text-decoration: line-through;
    color: $missing;
  }

  &.failed {
    color: $failed;
    &:hover {
      color: color.scale($failed, $lightness: $highlight, $saturation: $highlight);
    }
  }

  &.finished {
    color: $finished;
    &:hover {
      color: color.scale($finished, $lightness: $highlight, $saturation: $highlight);
    }
  }

  &.active {
    color: $active;
    &:hover {
      color: color.scale($active, $lightness: $highlight, $saturation: $highlight);
    }
  }

  &.inactive {
    color: $inactive;
    &:hover {
      color: color.scale($inactive, $lightness: $highlight, $saturation: $highlight);
    }
  }

  &.failed, &.finished, &.active, &.inactive {
    cursor: pointer;
  }
}
</style>
