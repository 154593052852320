<template>
  <teleport to="#modals">
    <div class="overlay">
      <div class="window">
        <header>
          <h3>
            <slot name="header"></slot>
          </h3>
        </header>
        <main>
          <slot></slot>
        </main>
        <footer>
          <div>
            <slot name="footer"></slot>
          </div>
        </footer>
      </div>
    </div>
  </teleport>
</template>

<style lang="scss" scoped>
.overlay {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;
}

.window {
  min-width: 500px;
}

header {
  background-color: #ddd;
  padding: 0 6px;
  height: 38px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;

  display: flex;
  align-items: center;
  justify-content: center;

  h3 {
    margin: 0;
  }
}

main {
  background-color: white;
  padding: 10px 18px;
}

footer {
  background-color: #ddd;
  padding: 0 18px;
  height: 38px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;

  display: flex;
  align-items: center;
  justify-content: flex-end;
}
</style>
