<template>
  <div>
    <h3>Users</h3>
    <div style="float: right; margin-bottom: 20px">
      <input type="text" placeholder="" v-model="keyword" @keydown.enter.prevent="searchUser">
      <button @click="searchUser">Search</button>
    </div>
    <div>
      <table class="list">
        <tr>
          <th>ID</th>
          <th>Info</th>
          <th>Total files</th>
          <th>Total size</th>
          <th>Actions</th>
        </tr>
        <tr v-for="user in users" :key="user.id">
          <td style="text-align: center;">{{ user.id }}</td>
          <td>
            Email: {{ user.email }} <br>
            Code: {{ user.code }} <br>
            Balance: {{ user.balance }} <br>
            Earner: <span v-if="user.earner" style="color: green;">Yes</span>
            <span v-else>No </span> <br>
            VIP: <span v-if="user.vip" style="color: green;">Yes</span>
            <span v-else>No </span> <br>
            Banned: <span v-if="user.banned" style="color: red;">Yes</span>
            <span v-else>No </span>
          </td>
          <td style="text-align: right;">{{ intComma(user.totalFiles) }}</td>
          <td>{{ fileSize(user.totalSize) }}</td>
          <td>
            <box-icon name="edit" @click="updateUser(user)" class="clickable" title="edit" />
          </td>
        </tr>
      </table>
    </div>
    <GDialog v-model="dialog_state" max-width="60%">
      <table class="list">
        <tr>
          <th scope="row">email:</th>
          <td>
            <input type="text" v-model="update_user.email"/>
          </td>
        </tr>
         <tr>
          <th scope="row">password:</th>
          <td>
            <input type="text" v-model="update_user.password"/>
            (only update when has value)
          </td>
        </tr>
        <tr>
          <th scope="row">balance : ({{update_user.balance}})</th>
          <td>
            <input type="text" v-model="balance"/>
            (adjust balance , n to add on current value, -n to reduce.)
          </td>
        </tr>
        <tr>
          <th scope="row">earner:</th>
          <td>
            <select v-model="update_user.earner">
              <option v-for="option in options"
                      :key="option.text"
                      :value="option.value"
                      :selected="update_user.earner === option.value">
                {{ option.text }}
              </option>
            </select>
          </td>
        </tr>
        <tr>
          <th scope="row">vip:</th>
          <td>
            <select v-model="update_user.vip">
              <option v-for="option in options"
                      :key="option.text"
                      :value="option.value"
                      :selected="update_user.vip === option.value">
                {{ option.text }}
              </option>
            </select>
          </td>
        </tr>
        <tr>
          <th scope="row">banned:</th>
          <td>
            <select v-model="update_user.banned">
              <option v-for="option in options"
                      :key="option.text"
                      :value="option.value"
                      :selected="update_user.banned === option.value">
                {{ option.text }}
              </option>
            </select>
          </td>
        </tr>
        <tr>
          <td/>
          <td>
            <button @click="update_the_user(update_user.id)">Update</button>
            <button @click="close_dialog()" style="margin-left: 20px">Cancel</button>
          </td>
        </tr>
      </table>

    </GDialog>

    <my-pagination :pagination="pagination" @go-to-page="goToPage($event)" v-if="pagination"/>
  </div>
</template>

<script>
import { intComma, fileSize } from 'humanize-plus';
import myaxios from '../libs/myaxios';

export default {
  data() {
    return {
      update_user: {},
      dialog_state: false,
      pagination: {},
      page: 1,
      users: [],
      keyword: '',
      balance: 0,
      options: [
        { text: 'Yes', value: true },
        { text: 'No', value: false },
      ],
    };
  },
  methods: {
    intComma,
    fileSize,
    get_users() {
      myaxios.get('/users', {
        params: {
          status: this.query_status,
          page: this.$route.query.page,
        },
      }).then((response) => {
        this.users = response.data.items;
        this.pagination = response.data.pagination;
      }).catch((e) => {
        this.$notify({ type: 'error', text: e.code });
      });
    },
    updateUser(user) {
      this.update_user = user;
      this.dialog_state = true;
    },
    update_the_user(id) {
      const config = { headers: { 'Content-Type': 'application/json' } };

      myaxios.post(`/user/${id}`, {
        id,
        email: this.update_user.email,
        password: this.update_user.password,
        balance: this.balance,
        earner: this.update_user.earner,
        vip: this.update_user.vip,
        banned: this.update_user.banned,
      },
      config)
        .then(() => {
          this.$notify({ type: 'success', text: 'success' });
          this.get_users();
          this.close_dialog();
        }).catch((e) => {
          this.$notify({ type: 'error', text: e.code ?? 'server_error' });
        });
    },
    searchUser() {
      myaxios.get('/users', { params: { keyword: this.keyword } }).then((response) => {
        this.users = response.data.items;
        this.pagination = response.data.pagination;
      }).catch((e) => {
        this.$notify({ type: 'error', text: e.code });
      });
    },
    goToPage(page) {
      this.page = page;
      this.$router.push({
        path: '/users',
        query:
          {
            page,
          },
      });
    },
    close_dialog() {
      this.dialog_state = false;
    },
  },
  created() {
    this.$watch('dialog_state', () => {
      this.get_users();
    });
    this.$emit('update:layout', 'default');
    this.get_users();
  },
  watch: {
    $route() {
      this.get_users();
    },
  },
};

</script>
